import {
  Grid,
  Card,
  Box,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  IconButton,
  Link,
  Avatar,
  Typography,
} from '@mui/material';
import Web from '../../../Images/web.png';
import Github from '../../../Images/github.png';

export default function SchoolProject(props) {
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      {props.schoolProjects.map((project) => {
        return (
          <Grid item xs={12} md={6} lg={4} key={project.name}>
            <Card
              sx={{
                maxWidth: '100%',
                minHeight: '550px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <CardActionArea
                  onClick={() => props.handleModal(project.image)}
                >
                  <CardMedia
                    sx={{ height: 280 }}
                    image={project.image}
                    title={project.name}
                  />
                </CardActionArea>
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{ color: 'primary.main', textAlign: 'center' }}
                  >
                    {project.name}
                  </Typography>
                  <Typography variant="body1" sx={{ textIndent: '20px' }}>
                    {project.description}
                  </Typography>
                </CardContent>
              </Box>
              <CardActions
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconButton>
                  <Link
                    href={project.github_link}
                    target="_blank"
                    sx={{ color: 'secondary.main' }}
                  >
                    <Avatar
                      variant="rounded"
                      alt={project.name}
                      src={Github}
                      sx={{
                        width: { xs: 30, sm: 48 },
                        height: { xs: 30, sm: 48 },
                      }}
                    />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link
                    href={project.web_link}
                    target="_blank"
                    sx={{ color: 'secondary.main' }}
                  >
                    <Avatar
                      variant="rounded"
                      alt="Website Logo"
                      src={Web}
                      sx={{
                        width: { xs: 30, sm: 48 },
                        height: { xs: 30, sm: 48 },
                      }}
                    />
                  </Link>
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
