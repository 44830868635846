import { Box, Avatar, Grid } from '@mui/material';

export default function GetIcons(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {props.array.map((icon, index) => {
          return (
            <Grid
              item
              xs={2}
              sm={3}
              md={3}
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Avatar
                variant="rounded"
                alt="web developer stack icon"
                src={icon}
                sx={{ width: 48, height: 48 }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
