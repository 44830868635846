import {
  Typography,
  Box,
  Container,
  Avatar,
  CircularProgress,
} from '@mui/material';
import Profile from '../../../Images/profile.png';
import TypewriterEffect from '../../Effects/Typewriter';
import { app } from '../../../config/firebase';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { useEffect, useState } from 'react';
import GetIcons from './geticons';

export default function HomePage() {
  const [loader, setLoader] = useState(true);
  const [iconsArray, setIconsArray] = useState();
  const [error, setError] = useState('');

  const texts = [
    "Hi, I'm a Front-End Web Developer.",
    'Hei, Jeg er Front-End Web Utvikler.',
    'Uy, Front-End Web Developer diay ko.',
  ];

  useEffect(() => {
    if (loader) {
      const storage = getStorage(app);

      const listRef = ref(storage, 'experienced');

      const listArray = [];

      listAll(listRef)
        .then((res) => {
          res.items.forEach((itemRef) => {
            getDownloadURL(ref(storage, itemRef.fullPath)).then((url) => {
              if (res.items.length - 1 === listArray.length) {
                listArray.push(url);
                setIconsArray(listArray);
                setLoader(false);
              } else {
                listArray.push(url);
              }
            });
          });
        })
        .catch((error) => {
          setError(error);
          setLoader(false);
        });
    }
  }, [loader]);

  if (loader) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'primary.main',
          py: 3,
          minHeight: '86.5vh',
        }}
      >
        <CircularProgress sx={{ color: 'secondary.main' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'primary.main',
          py: 3,
          minHeight: '86.5vh',
        }}
      >
        <Typography variant="h5">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: 'primary.main', py: 3 }}>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
          minHeight: '81.6vh',
        }}
      >
        <Box
          maxWidth="lg"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Avatar
            variant="rounded"
            alt="My profile"
            src={Profile}
            sx={{ width: { xs: 150, md: 250 }, height: { xs: 150, md: 250 } }}
          />
          <TypewriterEffect texts={texts} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifySelf: 'start',
            gap: 2,
          }}
        >
          <Typography variant="h5">Experienced :</Typography>
          <GetIcons array={iconsArray} />
        </Box>
      </Container>
    </Box>
  );
}
