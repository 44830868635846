import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { app } from '../../../config/firebase';
import { red } from '@mui/material/colors';

export default function Contact(props) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [loader, setLoader] = useState(false);
  const [sendMessage, setSendMessage] = useState('');

  function SendMessage() {
    /* eslint-disable-next-line */
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailError('');
      if (message) {
        setMessageError('');
        setLoader(true);
        const db = getFirestore(app);
        addDoc(collection(db, 'mail'), {
          to: process.env.REACT_APP_EMAIL,
          message: {
            subject: email,
            text: message,
            html: message,
          },
        }).then((docRef) => {
          if (docRef.id) {
            setSendMessage('Message sent. Thank you.');
          } else {
            setSendMessage(
              'Sorry, we have problem sending your message. Try reloading page or try maybe later.',
            );
          }
          setLoader(false);
        });
      } else {
        setMessageError('Message input must not be empty.');
      }
    } else {
      setEmailError('Invalid Email Address');
    }
  }

  if (loader) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          minWidth: '350px',
          maxWidth: '500px',
        }}
      >
        <Button onClick={props.handleClose} sx={{ alignSelf: 'flex-end' }}>
          <HighlightOffRoundedIcon sx={{ color: 'primary.main' }} />
        </Button>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
          }}
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (sendMessage) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          minWidth: '350px',
          maxWidth: '500px',
        }}
      >
        <Button onClick={props.handleClose} sx={{ alignSelf: 'flex-end' }}>
          <HighlightOffRoundedIcon sx={{ color: 'primary.main' }} />
        </Button>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
          }}
        >
          <Typography variant="h5" sx={{ color: 'primary.main', p: 2 }}>
            {sendMessage}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: 'white', width: { sm: '500px', xs: '100%' } }}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          m: 1,
        }}
      >
        <Button onClick={props.handleClose} sx={{ alignSelf: 'flex-end' }}>
          <HighlightOffRoundedIcon sx={{ color: 'primary.main' }} />
        </Button>
        <Typography variant="h5" sx={{ color: 'primary.main' }}>
          Send Me A Message
        </Typography>
        <Box sx={{ my: 2, width: '100%' }}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            defaultValue={email}
            fullWidth
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Typography variant="body2" sx={{ color: red[900] }}>
            {emailError}
          </Typography>
        </Box>
        <Box sx={{ my: 2, width: '100%' }}>
          <Box
            id="message"
            component="textarea"
            rows="10"
            cols="35"
            maxLength="500"
            placeholder="Type your message here...."
            defaultValue={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            sx={{
              p: 1,
              fontFamily: 'Kalam-Bold',
              border: '2px solid',
              borderRadius: '5px',
              width: '100%',
            }}
          />
          <Typography variant="body2" sx={{ color: red[900] }}>
            {messageError}
          </Typography>
        </Box>
        <Button variant="contained" sx={{ m: 2 }} onClick={SendMessage}>
          Send
        </Button>
      </Box>
    </Box>
  );
}
