import {
  Typography,
  Box,
  Container,
  Dialog,
  CircularProgress,
} from '@mui/material';
import { useState, useEffect } from 'react';
import SchoolProject from './school';
import PersonalProject from './personal';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { app } from '../../../config/firebase';

export default function ProjectPage() {
  const [modal, setModal] = useState(false);
  const [modalImg, setModalImg] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState('');
  const [personalProjects, setPersonalProjects] = useState();
  const [schoolProjects, setSchoolProjects] = useState();

  useEffect(() => {
    if (loader) {
      const db = getFirestore(app);

      const schoolList = [];
      const personalList = [];

      getDocs(collection(db, 'projects', 'school', 'schoolprojects'))
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            schoolList.push(doc.data());
          });
        })
        .then(() => {
          getDocs(
            collection(db, 'projects', 'personal', 'personalprojects'),
          ).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (querySnapshot.docs.length - 1 === personalList.length) {
                personalList.push(doc.data());
                setSchoolProjects(schoolList);
                setPersonalProjects(personalList);
                setLoader(false);
              } else {
                personalList.push(doc.data());
              }
            });
          });
        })
        .catch((error) => {
          setError(error);
          setLoader(false);
        });
    }
  }, [loader]);

  const handleModal = (img) => {
    setModalImg(img);
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  if (loader) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'secondary.main',
          py: 3,
          minHeight: '86.5vh',
        }}
      >
        <CircularProgress sx={{ color: 'primary.main' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'secondary.main',
          py: 3,
          minHeight: '86.5vh',
        }}
      >
        <Typography variant="h5" sx={{ color: 'primary.main' }}>
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ py: 2 }}>
        <Container maxWidth="lg" sx={{ minHeight: '81.6vh' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 5,
            }}
          >
            <Typography variant="h5" sx={{ color: 'primary.main' }}>
              School Projects
            </Typography>
            <SchoolProject
              schoolProjects={schoolProjects}
              handleModal={handleModal}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h5" sx={{ color: 'primary.main' }}>
              Personal Projects
            </Typography>
            <PersonalProject
              personalProjects={personalProjects}
              handleModal={handleModal}
            />
          </Box>
        </Container>
      </Box>
      <Dialog open={modal} onClose={handleClose}>
        <Box component="img" src={modalImg} />
      </Dialog>
    </>
  );
}
