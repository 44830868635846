import { Box, Typography } from '@mui/material';

export default function ProgrammingExperience(props) {
  return (
    <Box component="ul" sx={{ mb: 2 }}>
      {props.programming[0].map((list, i) => {
        return (
          <Box component="li" key={i}>
            <Typography
              variant="body1"
              sx={{ fontFamily: 'Helvetica Neue', fontWeight: 'bold' }}
            >
              {list}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
