import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

export default function WorkExperience(props) {
  return (
    <Box component="ul" sx={{ mb: 2 }}>
      {props.work.map((list, i) => {
        return (
          <Box component="li" key={i} sx={{ width: '100%', mb: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={'panel' + i + '-content'}
                id={'panel' + i + '-header'}
              >
                <Typography
                  variant="body1"
                  sx={{ fontFamily: 'Helvetica Neue', fontWeight: 'bold' }}
                >
                  {list.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box component="ul">
                  <Box
                    component="li"
                    sx={{ display: 'flex', flexDirections: 'row' }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: 'Helvetica Neue', fontWeight: 'bold' }}
                    >
                      Company&nbsp;:&nbsp;
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: 'Helvetica Neue', fontWeight: 'bold' }}
                    >
                      {list.company}
                    </Typography>
                  </Box>
                  <Box
                    component="li"
                    sx={{ display: 'flex', flexDirections: 'row' }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: 'Helvetica Neue', fontWeight: 'bold' }}
                    >
                      Description&nbsp;:&nbsp;
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: 'Helvetica Neue', fontWeight: 'bold' }}
                    >
                      {list.description}
                    </Typography>
                  </Box>
                  <Box
                    component="li"
                    sx={{ display: 'flex', flexDirections: 'row' }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: 'Helvetica Neue', fontWeight: 'bold' }}
                    >
                      Start Date&nbsp;:&nbsp;
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: 'Helvetica Neue', fontWeight: 'bold' }}
                    >
                      {list.startDate}
                    </Typography>
                  </Box>
                  <Box
                    component="li"
                    sx={{ display: 'flex', flexDirections: 'row' }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: 'Helvetica Neue', fontWeight: 'bold' }}
                    >
                      End Date&nbsp;:&nbsp;
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: 'Helvetica Neue', fontWeight: 'bold' }}
                    >
                      {list.endDate}
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}
    </Box>
  );
}
