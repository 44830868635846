import { Box, Container, Typography, CircularProgress } from '@mui/material';
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
} from 'firebase/firestore';
import { app } from '../../../config/firebase';
import { useState, useEffect } from 'react';
import WorkExperience from './work';
import ProgrammingExperience from './programming';

export default function AboutMePage() {
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState('');
  const [workExperience, setWorkExperience] = useState();
  const [programmingExperience, setProgrammingExperience] = useState();
  const [bio, setBio] = useState('');

  const date = new Date().toISOString();
  const year = parseInt(date.split('-')[0]);
  const month = parseInt(date.split('-')[1]);
  const day = parseInt(date.split('-')[2]);
  let age;

  if (month < 5 && day < 9) {
    age = year - 1990;
  } else {
    age = year - 1989;
  }

  useEffect(() => {
    if (loader) {
      const db = getFirestore(app);

      const workList = [];
      const programmingList = [];

      getDocs(collection(db, 'about me', 'experienced', 'work'))
        .then((querySnapShot) => {
          querySnapShot.forEach((doc) => {
            workList.push(doc.data());
          });
        })
        .then(() => {
          getDocs(
            collection(db, 'about me', 'experienced', 'programming'),
          ).then((querySnapShot) => {
            querySnapShot.forEach((doc) => {
              programmingList.push(doc.data().text);
            });
          });
        })
        .then(() => {
          getDoc(doc(db, 'about me', 'bio')).then((doc) => {
            setBio(doc.data().text);
            setWorkExperience(workList);
            setProgrammingExperience(programmingList);
            setLoader(false);
          });
        })
        .catch((error) => {
          setError(error);
          setLoader(false);
        });
    }
  }, [loader]);

  if (loader) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'secondary.main',
          py: 3,
          minHeight: '86.5vh',
        }}
      >
        <CircularProgress sx={{ color: 'primary.main' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'secondary.main',
          py: 3,
          minHeight: '86.5vh',
        }}
      >
        <Typography variant="h5" sx={{ color: 'primary.main' }}>
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ py: 2 }}>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          minHeight: '81.6vh',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: 'primary.main',
            mb: 2,
            fontFamily: 'Helvetica Neue',
            fontWeight: 'bold',
            alignSelf: 'center',
          }}
        >
          About Me
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textIndent: '20px',
            mb: 2,
            fontFamily: 'Helvetica Neue',
            fontWeight: 'bold',
          }}
        >
          I'm {age} {bio}
        </Typography>
        <Box
          sx={{
            backgroundColor: 'primary.main',
            marginLeft: 0,
            borderRadius: '5px',
            alignSelf: 'start',
            width: { xs: '100%', sm: '500px' },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              p: 1,
              fontFamily: 'Helvetica Neue',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}
          >
            Work Experience
          </Typography>
        </Box>
        <WorkExperience work={workExperience} />
        <Box
          sx={{
            backgroundColor: 'primary.main',
            marginLeft: 0,
            borderRadius: '5px',
            alignSelf: 'start',
            width: { xs: '100%', sm: '500px' },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              p: 1,
              fontFamily: 'Helvetica Neue',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}
          >
            Programming Experience
          </Typography>
        </Box>
        <ProgrammingExperience programming={programmingExperience} />
      </Container>
    </Box>
  );
}
